import { Modal, Spin } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import employeeContractApi from 'api/employeeContractApi';
import { CloseIcon } from 'assets/icons';
import ITVPassCode from 'components/ITVPassCode';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import { useTranslation } from 'react-i18next';
import { ContractDetail } from 'types';
import { getUser } from 'utils/auth';
import { getErrorMessage } from 'utils/getErrorMessage';
import { ViewFilePDFModal } from './ViewFilePDFModal';

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    .ant-modal-header {
      border-bottom: none;
      border-radius: 12px;
      padding: 16px 24px 8px;
    }
    .ant-modal-body {
      padding: 8px 24px 16px;
    }
  }
`;

const StyledWrapperOTP = styled.div`
  text-align: center;
  p {
    margin-bottom: 0;
    margin-top: 12px;
  }
`;

const StyledVerifyOTPSubTitle = styled.div`
  font-size: 1rem;
  color: var(--cl_text_primary);
  text-align: center;
  margin-bottom: 12px;
`;

interface VerifyOTPContractModalProps {
  title: ReactNode;
  isOpen: boolean;
  onCancel?: () => void;
  item: ContractDetail;
  fromScreen?: string;
}

export function VerifyOTPContractModal({
  title,
  isOpen,
  onCancel,
  item,
  fromScreen,
}: VerifyOTPContractModalProps) {
  const { t } = useTranslation(['contractManagement']);

  const [pdfUrl, setPdfUrl] = useState('');

  const [reSend, setResend] = useState(false);
  const [loadingSendOTP, setLoadingSendOTP] = useState(false);
  const [loadingVerifyOTP, setLoadingVerifyOTP] = useState(false);

  const [isOpenViewFilePDFModel, setIsOpenViewFilePDFModel] = useState(false);

  const handleCancelViewFiledPDFModal = () => {
    setIsOpenViewFilePDFModel(false);
  };

  useEffect(() => {
    if (isOpen) {
      handleGetOTP();
    }
  }, [isOpen]);

  const handleGetOTP = async () => {
    try {
      setLoadingSendOTP(true);
      const response = await employeeContractApi.sendOTPViewContractDocument({
        email: item.email,
        contractId: Number(item.id),
      });

      setLoadingSendOTP(false);
      if (!response?.data?.id && !response?.data?.userId) return;
      setResend(true);
    } catch (error) {
      setLoadingSendOTP(false);
      const message = getErrorMessage(error);
      notificationToast(Notification.Type.Error, message, Notification.Duration._3s);
    }
  };

  const handleSubmit = async (otp: string) => {
    try {
      setLoadingVerifyOTP(true);
      const response = await employeeContractApi.verifyOTPGetContractDocument({
        otp,
        userId: getUser().id,
        contractId: item?.id,
      });
      setLoadingVerifyOTP(false);

      if (!response?.data) return;

      // onCancel && onCancel();
      setPdfUrl(response?.data);
    } catch (error) {
      setLoadingVerifyOTP(false);
      const message = getErrorMessage(error);
      notificationToast(Notification.Type.Error, message, Notification.Duration._3s);
    }
  };

  useEffect(() => {
    if (pdfUrl) {
      setIsOpenViewFilePDFModel(true);
    }
  }, [pdfUrl]);

  return (
    <React.Fragment>
      <StyledModal
        title={title}
        open={isOpen}
        closeIcon={<CloseIcon />}
        width={500}
        footer={null}
        maskClosable={false}
        onCancel={() => onCancel && onCancel()}
      >
        {reSend && (
          <StyledVerifyOTPSubTitle>{t('verify_otp_modal.sub_title')}</StyledVerifyOTPSubTitle>
        )}

        <Spin spinning={loadingVerifyOTP}>
          <StyledWrapperOTP>
            <ITVPassCode
              length={6}
              isDisabled={loadingVerifyOTP}
              setResult={(value: string) => handleSubmit(value)}
            />
            {loadingSendOTP ? (
              <div>
                <Spin spinning={loadingSendOTP}></Spin>
              </div>
            ) : !reSend ? (
              <p>
                {t('verify_otp_modal.not_otp')}, {t('verify_otp_modal.please')}{' '}
                <i
                  style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={handleGetOTP}
                >
                  {t('verify_otp_modal.click_here')}
                </i>{' '}
                {t('verify_otp_modal.get_otp')}.
              </p>
            ) : (
              <p>
                {t('verify_otp_modal.not_get_otp')}, {t('verify_otp_modal.please')}{' '}
                <i
                  style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={handleGetOTP}
                >
                  {t('verify_otp_modal.click_here')}
                </i>{' '}
                {t('verify_otp_modal.receive')}
              </p>
            )}
          </StyledWrapperOTP>
        </Spin>
      </StyledModal>
      {pdfUrl && (
        <ViewFilePDFModal
          pdfURL={pdfUrl}
          isOpen={isOpenViewFilePDFModel}
          onCancel={handleCancelViewFiledPDFModal}
          fromScreen={fromScreen}
          contractDetail={item}
        />
      )}
    </React.Fragment>
  );
}
