import { Select, Switch, Tabs } from 'antd';
import ButtonBack from 'components/ButtonBack';
import ITVTable from 'components/ITVTable';
import { searchEmployee } from 'features/employee/employeeAction';
import { getEquipmentsByEmployee } from 'features/equipment/equipmentSlice';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import PageHeader from 'components/PageHeader';
import { useAppSelector } from 'app/hooks';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { Container } from '../styled';
import styled from 'styled-components';
import TextArea from 'antd/lib/input/TextArea';
import { useController } from 'react-hook-form';
import { InputField, SelectField, TextAreaField } from 'components/FormFields';
import { selectPerformanceData } from 'features/performance/performanceSlice';
import { getUser } from 'utils/auth';
import { getRoleList, selectRole } from 'features/role/roleSlice';
import { getCategoryWithType } from 'features/configuration/categoryAction';
import { useTranslation } from 'react-i18next';

const filterDefault = {
  sortBy: 'status',
  orderBy: 'asc',
};

const HeaderPerformance = (props: any) => {
  const { control, error, getValues, setValue } = props;
  const { t } = useTranslation(['performance', 'performance_review']);
  const dispatch = useDispatch();
  const location: any = useLocation();
  const history = useHistory();
  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const [titleHeader, setTitleHeader] = useState('');
  const performanceStore = useAppSelector(selectPerformanceData);
  const assessmentDetail = performanceStore?.assessmentDetail;
  const role = useAppSelector(selectRole).data;
  const dataConfiguration = useAppSelector(selectConfiguration);
  const level = dataConfiguration.dataLevel || [];

  const [disable, setDisable] = useState(false);

  const roleOptions = role?.map((x: any) => ({
    label: x?.name,
    value: x?.id,
  }));

  useEffect(() => {
    dispatch(
      getRoleList({
        filters: filterDefault,
      }),
    );
    dispatch(
      getCategoryWithType({
        typeConfig: 'level',
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    const checkIsSomeAllowEdit = assessmentDetail?.steps?.some((step) =>
      step?.data?.some((dt) => dt?.allowEdit),
    );
    const checkIncludeInStep = assessmentDetail?.steps?.some((step) =>
      step?.data?.some((dt) => dt?.assessorId === getUser()?.id),
    );
    // console.log('disable',disable,'checkIsSomeAllowEdit',checkIsSomeAllowEdit,`getValues('allowEditAsessment')`,getValues('allowEditAsessment'))
    // if (!checkIsSomeAllowEdit && checkIncludeInStep) {
    if (!getValues('isShowAccept') || !getValues('allowEditAsessment')) {
      if (getValues('allowEditAsessment') && !assessmentDetail?.isLastSeen) {
        setDisable(false);
      } else setDisable(true);
    }
    // }
  }, [assessmentDetail]);

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item: any) => location.pathname === item.path)
      ?.map((item: any) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  const { field: isPromotedField } = useController({
    name: 'isPromoted',
    control,
    defaultValue: getValues('isPromoted'),
  });

  return (
    <WrapContainer style={{ marginBottom: 8 }}>
      <WrapContainer style={{ flexDirection: 'column', flex: 2 }}>
        <WrapContainer>
          <div style={{ flex: 1 }}>{t('performance:performance_review.review_cycle_name')}</div>
          <div style={{ flex: 2 }}>{getValues ? getValues('reviewCycle.name') : ''}</div>
        </WrapContainer>
        <WrapContainer>
          <div style={{ flex: 1 }}>{t('performance_review:description')}</div>
          <div style={{ flex: 2 }}>{getValues ? getValues('reviewCycle.description') : ''}</div>
        </WrapContainer>
        <WrapContainer>
          <div style={{ flex: 1 }}>{t('performance_review:total_rating')}</div>
          <div style={{ flex: 2 }}>
            {getValues && getValues('totalRating') ? (
              <WrapTotalValid>{getValues('totalRating')}</WrapTotalValid>
            ) : (
              <></>
            )}
          </div>
        </WrapContainer>
      </WrapContainer>
      <WrapContainer style={{ flexDirection: 'column', flex: 1 }}>
        <WrapContainer>
          <div style={{ flex: 2 }}>{t('performance:pending_review.current_level')}</div>
          <InputField disabled={true} control={control} name="currentLevel" />
        </WrapContainer>
        <WrapContainer>
          <div style={{ flex: 2 }}>{t('performance_review:current_role')}</div>
          <InputField disabled={true} control={control} name="currentRole" />
        </WrapContainer>
        <WrapContainer>
          <div style={{ flex: 2 }}>{t('performance_review:promoted')}</div>
          <Switch
            disabled={disable}
            defaultChecked={getValues('isPromoted')}
            style={{ width: 15 }}
            {...isPromotedField}
          />
        </WrapContainer>
      </WrapContainer>
      <WrapContainer style={{ flexDirection: 'column', flex: 1 }}>
        <WrapContainer>
          <div style={{ flex: 2 }}>{t('performance_review:new_level')}</div>
          <div style={{ width: '100%' }}>
            <SelectField
              options={level || []}
              disabled={disable}
              control={control}
              name="newLevel"
            />
          </div>
        </WrapContainer>
        <WrapContainer>
          <div style={{ flex: 2 }}>{t('performance_review:new_role')}</div>
          <div style={{ width: '100%' }}>
            <SelectField
              options={roleOptions || []}
              disabled={disable}
              control={control}
              name="newRole"
            />
          </div>
        </WrapContainer>
        <WrapContainer style={{ flex: 3 }}></WrapContainer>
      </WrapContainer>
      <WrapContainer style={{ flexDirection: 'column', flex: 1.25 }}>
        <WrapContainer>
          <div style={{ flex: 2 }}>
            <div>{t('performance_review:general_assessment')}</div>
            <TextAreaField
              disabled={disable}
              control={control}
              name="general"
              style={{ height: '100%' }}
            />
          </div>
        </WrapContainer>
      </WrapContainer>
    </WrapContainer>
  );
};

export default HeaderPerformance;

const WrapContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const WrapTotalValid = styled.div`
  background-color: #cef7e0;
  width: fit-content;
  padding: 0px 10px;
  border: 1px solid #aeefca;
  border-radius: 10px;
`;
