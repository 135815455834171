import { Card, Col, Row } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  DateRangePickerField,
  InputField,
  SelectField,
  TextAreaField,
} from 'components/FormFields';
import { YYYY_MM_DD } from 'constants/commons';
import { selectPerformanceData } from '../performanceSlice';
import { useEffect, useState } from 'react';
import { getPerformanceTemplate } from '../performanceAction';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const GeneralInformation = ({ control, watch, unregister, getValues, viewMode, setValue }) => {
  const { t } = useTranslation(['PTIManagement', 'performance']);
  const dispatch = useAppDispatch();
  const performanceStore = useAppSelector(selectPerformanceData);
  const performanceTemplate = performanceStore?.performanceTemplate?.result;
  const [templates, setTemplates] = useState([]);
  const shouldDisable = getValues()?.status !== 'New';

  useEffect(() => {
    if (performanceTemplate) {
      const newArray = performanceTemplate?.map((tpl) => ({ label: tpl?.name, value: tpl?.id }));
      setTemplates(newArray);
    }
  }, [performanceTemplate]);

  const watchTemplate = watch('templateId');

  useEffect(() => {
    if (watchTemplate) {
      dispatch(getPerformanceTemplate({ id: watchTemplate }));
      if (getValues) {
        const steps = Object.keys(getValues()).filter((key) => key.startsWith('stepId_'));
        // steps.forEach((step) => unregister(step));
        // unregister && unregister('stepScheduler');
      }
    }
  }, [watchTemplate]);

  return (
    <Card
      title={t('PTIManagement:claim_insurance.title_content_1.sub_title_content_1')}
      bordered={false}
    >
      <Row gutter={16}>
        <Col span={24}>
          <label>
            {t('performance:performance_review.review_cycle_name')}{' '}
            <span style={{ color: 'red' }}>*</span>
          </label>
          <InputField
            name="name"
            control={control}
            type="text"
            placeholder={t('performance:performance_review.review_cycle_name_placeholder')}
            disabled={viewMode && shouldDisable}
            rules={{
              required: {
                value: true,
                message: `${t('validation:required', {
                  field: t('performance:performance_review.review_cycle_name'),
                })}`,
              },
            }}
          />
        </Col>
        <Col span={24}>
          <label>{t('performance:performance_review.review_scope')}</label>
          <SelectField
            options={[
              { label: 'All', value: 'All' },
              { label: 'List', value: 'List' },
            ]}
            control={control}
            name="scope"
            disabled={viewMode && shouldDisable}
          ></SelectField>
        </Col>
        <Col span={24}>
          <label>
            {t('performance:performance_review.review_template')}{' '}
            <span style={{ color: 'red' }}>*</span>
          </label>
          {viewMode && shouldDisable ? (
            <InputField name="templatesHistory.name" control={control} disabled={viewMode} />
          ) : (
            <SelectField
              options={templates}
              control={control}
              name="templateId"
              rules={{
                required: {
                  value: true,
                  message: `${t('validation:required', {
                    field: t('performance:performance_review.review_template'),
                  })}`,
                },
              }}
            ></SelectField>
          )}
        </Col>
        <Col span={24}>
          <label>
            {t('performance:performance_review.period')} <span style={{ color: 'red' }}>*</span>
          </label>
          <WrapperPeriod>
            <DateRangePickerField
              disable={viewMode && shouldDisable}
              format={YYYY_MM_DD}
              name="period"
              control={control}
              allowClear
            />
          </WrapperPeriod>
        </Col>
        <Col span={24}>
          <label>{t('performance:performance_review.review_description')}</label>
          <TextAreaField
            disabled={viewMode && shouldDisable}
            control={control}
            name="description"
            placeholder={t('performance:performance_review.enter_description')}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default GeneralInformation;
export const WrapperPeriod = styled.div`
  div > div > div {
    justify-content: space-between;
  }
`;
