import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { Tabs, Button, Space } from 'antd';

import payrollApi from 'api/payrollApi';
import { ThirteenthMonthSalaryData, ThirteenthMonthSalaryFilters } from 'types';
import { ContainerBgWhite } from 'styles/containerBgWhite';
import { PAYROLL_YEAR_SORT_FIELDS, SORT_TYPES } from 'constants/payroll';
import ThirteenthMonthSalaryList from './ThirteenthMonthSalaryList';
import SelectEmployeeModal from './SelectEmployeeModal';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import { SearchOutlined, SyncOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { message } from 'antd';
import debounce from 'lodash/debounce';

const SearchWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .search-input {
    .ant-input-affix-wrapper {
      max-width: 300px;
    }
  }

  .left-section {
    display: flex;
    gap: 16px;
    align-items: center;
    flex: 1;
  }
  
  .right-section {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-end;
    margin-left: auto;
  }

  }
`;

const TabsWrapper = styled.div`
  margin-bottom: 16px;

  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs-tab {
    min-width: 120px;
    padding: 8px 24px;
    justify-content: center;
    transition: none !important;

    .ant-tabs-tab-btn {
      text-align: center;
      transition: none !important;
    }
  }

  .ant-tabs-ink-bar {
    transition: none !important;
  }
`;

interface ThirteenthMonthSalaryProps {
  year: string;
}

const ThirteenthMonthSalary: React.FC<ThirteenthMonthSalaryProps> = ({ year }) => {
  const { t } = useTranslation(['payroll', 'common']);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ThirteenthMonthSalaryData[]>([]);
  const [totalResult, setTotalResult] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [activeTab, setActiveTab] = useState('gross');
  const [isCreating, setIsCreating] = useState(false);
  const [allData, setAllData] = useState<ThirteenthMonthSalaryData[]>([]);

  const [filters, setFilters] = useState<ThirteenthMonthSalaryFilters>({
    search: '',
    year: year,
    include13thMonth: true,
  });

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      year: year,
      offset: page,
      limit,
    }));
  }, [year]);

  const filtersMemo = useMemo<ThirteenthMonthSalaryFilters>(
    () => ({
      search: searchText,
      year: year,
      include13thMonth: true,
      offset: page,
      limit,
    }),
    [year, searchText, page, limit],
  );

  const fetchData = async (resetPageIfNeeded = true) => {
    try {
      setIsLoading(true);
      const response = await payrollApi.getThirteenthMonthSalary(filtersMemo);

      const newData = response?.data?.data || [];
      const totalCount = response?.data?.total || 0;

      setData(newData);
      setTotalResult(totalCount);

      if (resetPageIfNeeded) {
        setPage(1);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error(t('common:message.error'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(false);
  }, [filtersMemo]);

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setSearchText(value);
      setPage(1);
    }, 350),
    [],
  );

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      debouncedSearch(e.target.value);
    },
    [debouncedSearch],
  );

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any>,
  ) => {
    if (pagination.current && pagination.current !== page) {
      setPage(pagination.current);
    }
    if (pagination.pageSize && pagination.pageSize !== limit) {
      setLimit(pagination.pageSize);
      setPage(1);
    }
  };

  const handleCreate13thSalary = async () => {
    try {
      setIsCreating(true);
      const response = await payrollApi.createPayroll13th(year);
      if (response?.data?.success) {
        message.success(t('payroll:refresh_13th_month_success'));
        setPage(1);
        setTimeout(() => {
          fetchData(false);
        }, 500);
      }
    } catch (error: any) {
      console.error('Error creating 13th month salary:', error);
      if (error?.statusCode === 404) {
        message.error(t('payroll:refresh_13th_month_failed'));
      } else {
        message.error(t('common:message.error'));
      }
    } finally {
      setIsCreating(false);
    }
  };

  const refetchData = useCallback(() => {
    fetchData(true);
  }, [year]);

  return (
    <ContainerBgWhite>
      <SearchWrapper>
        <div className="left-section">
          <div className="search-input">
            <Input
              placeholder={t('common:search by Name & ID')}
              prefix={<SearchOutlined />}
              onChange={handleSearch}
              allowClear
            />
          </div>
        </div>
        <div className="right-section">
          <Space>
            <Button
              type="primary"
              icon={<SyncOutlined />}
              onClick={handleCreate13thSalary}
              loading={isCreating}
            >
              {t('payroll:sync_13th_salary')}
            </Button>
            <SelectEmployeeModal year={year} onSuccess={fetchData} />
          </Space>
        </div>
      </SearchWrapper>
      <TabsWrapper>
        <Tabs
          activeKey={activeTab}
          onChange={(key) => setActiveTab(key)}
          items={[
            {
              key: 'gross',
              label: t('payroll:gross_salary'),
            },
            {
              key: 'net',
              label: t('payroll:net_salary'),
            },
          ]}
        />
      </TabsWrapper>

      <ThirteenthMonthSalaryList
        loading={isLoading}
        data={data}
        totalResult={totalResult}
        onTableChange={handleTableChange}
        page={page}
        limit={limit}
        setPage={setPage}
        setLimit={setLimit}
        refetch={fetchData}
        activeTab={activeTab}
        year={year}
      />
    </ContainerBgWhite>
  );
};

export default ThirteenthMonthSalary;
