import { Modal, Spin } from 'antd';
import payrollApi from 'api/payrollApi';
import { CloseIcon } from 'assets/icons';
import ITVPassCode from 'components/ITVPassCode';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getUser } from 'utils/auth';
import { getErrorMessage } from 'utils/getErrorMessage';

export default function VerifyOTPPayrollModal({
  title,
  handleVerifyOTP,
  type,
}: {
  type: 'payroll' | 'insurance';
  title?: string;
  handleVerifyOTP: (isVerifyOTP: boolean) => void;
}) {
  const { t } = useTranslation(['contractManagement']);

  const [isOpen, setIsopen] = useState(true);
  const [reSend, setResend] = useState(false);
  const [loadingSendOTP, setLoadingSendOTP] = useState(false);
  const [loadingVerifyOTP, setLoadingVerifyOTP] = useState(false);

  const [optDataResponse, setOptDataResponse] = useState();

  useEffect(() => {
    const isInputOTP = localStorage.getItem('isVerifyOTP');
    if (isInputOTP) {
      setIsopen(false);
      setLoadingVerifyOTP(true);
      handleVerifyOTP(true);
    }
  }, [localStorage.getItem('isVerifyOTP')]);

  useEffect(() => {
    if (isOpen) {
      handleGetOTP();
    }
  }, [isOpen]);

  const onCancel = () => {
    setIsopen(false);
  };

  const handleGetOTP = async () => {
    try {
      setLoadingSendOTP(true);
      const response = await payrollApi.sendOTPViewPayroll({
        email: getUser()?.email || '',
        type,
      });
      setLoadingSendOTP(false);
      if (!response?.data?.id && !response?.data?.userId) return;
      setOptDataResponse(response?.data);
      setResend(true);
    } catch (error) {
      setLoadingSendOTP(false);
      const message = getErrorMessage(error);
      notificationToast(Notification.Type.Error, message, Notification.Duration._3s);
    }
  };

  const handleSubmit = async (otp: string) => {
    try {
      setLoadingVerifyOTP(true);
      const response = await payrollApi.verifyOTPViewPayroll({
        otp,
        userId: getUser().id,
        id: optDataResponse?.id || '',
      });
      setLoadingVerifyOTP(false);

      if (!response?.data) return;
      handleVerifyOTP(true);
      onCancel();
      localStorage.setItem('isVerifyOTP', 'true');
    } catch (error) {
      setLoadingVerifyOTP(false);
      const message = getErrorMessage(error);
      notificationToast(Notification.Type.Error, message, Notification.Duration._3s);
    }
  };

  return (
    <StyledModal
      title={
        <StyledWrapperVerifyOTPTitle>
          <StyledVerifyOTPTitle>{t('verify_otp_modal.title')}</StyledVerifyOTPTitle>
        </StyledWrapperVerifyOTPTitle>
      }
      open={isOpen}
      closeIcon={<CloseIcon />}
      width={500}
      footer={null}
      maskClosable={false}
      onCancel={() => onCancel()}
    >
      <Spin spinning={loadingVerifyOTP}>
        <StyledWrapperOTP>
          <ITVPassCode
            length={6}
            isDisabled={loadingVerifyOTP}
            setResult={(value: string) => handleSubmit(value)}
          />
          {loadingSendOTP ? (
            <div>
              <Spin spinning={loadingSendOTP}></Spin>
            </div>
          ) : !reSend ? (
            <p>
              {t('verify_otp_modal.not_otp')}, {t('verify_otp_modal.please')}{' '}
              <i
                style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
                onClick={handleGetOTP}
              >
                {t('verify_otp_modal.click_here')}
              </i>{' '}
              {t('verify_otp_modal.get_otp')}.
            </p>
          ) : (
            <p>
              {t('verify_otp_modal.not_get_otp')}, {t('verify_otp_modal.please')}{' '}
              <i
                style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
                onClick={handleGetOTP}
              >
                {t('verify_otp_modal.click_here')}
              </i>{' '}
              {t('verify_otp_modal.receive')}
            </p>
          )}
        </StyledWrapperOTP>
      </Spin>
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    .ant-modal-header {
      border-bottom: none;
      border-radius: 12px;
      padding: 16px 24px 8px;
    }
    .ant-modal-body {
      padding: 8px 24px 16px;
    }
  }
`;

const StyledWrapperOTP = styled.div`
  text-align: center;
  p {
    margin-bottom: 0;
    margin-top: 12px;
  }
`;

const StyledVerifyOTPSubTitle = styled.div`
  font-size: 1rem;
  color: var(--cl_text_primary);
  text-align: center;
  margin-bottom: 12px;
`;

const StyledWrapperVerifyOTPTitle = styled.div`
  text-align: center;
`;

const StyledVerifyOTPTitle = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  letter-spacing: 0.5px;
  color: var(--cl_text_primary);
`;
