import { Modal, Select, Button } from 'antd';
import { selectFormatDate, selectFormatDatetime } from 'app/commonRedux/appSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import ITVSearch from 'components/ITVSearch';
import ITVTable from 'components/ITVTable';
import StatusView from 'components/StatusView';
import EmployeeColumn from 'features/employee/components/EmployeeColumn';
import {
  getHistoryProjectManageProjectDetail,
  getProjectList,
} from 'features/overtime/overtimeAction';
import { selectOvertimeData } from 'features/overtime/overtimeSlice';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { removeAccents } from 'utils/text';
import ViewProjectDetail from './ViewProjectDetail';
import XLSX from 'xlsx';

const { Option } = Select;

const ViewProject = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>([]);
  const [mode, setMode] = useState<string>('');
  const [currentItem, setCurrentItem] = useState<any>(null);
  const { t } = useTranslation(['validation', 'overtime', 'timesheet']);
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const dateFormat = useAppSelector(selectFormatDate);
  const formatDatetime = useAppSelector(selectFormatDatetime);
  const overtimeData = useAppSelector(selectOvertimeData);
  const dataHistoryProjectDetail = overtimeData?.dataHistoryProjectDetail;
  const loadingDataHistoryProjectDetail = overtimeData?.loadingDataHistoryProjectDetail;
  const listProject = overtimeData?.listProject;
  const [project, setProject] = useState('');

  const projectStatus = [
    { key: '', value: 'All' },
    { key: 'new', value: 'New' },
    { key: 'active', value: 'Active' },
    { key: 'pending', value: 'Pending' },
    { key: 'close', value: 'Close' },
  ];
  const [projectStatusState, setProjectStatusState] = useState('');
  const [text, setText] = useState('');

  useEffect(() => {
    dispatch(getProjectList());
  }, [dispatch]);

  useEffect(() => {
    const params = {
      projectCode: project,
      status: projectStatusState,
    };
    dispatch(getHistoryProjectManageProjectDetail(params));
  }, [projectStatusState, project, dispatch]);

  useEffect(() => {
    if (dataHistoryProjectDetail) {
      if (text) {
        const temp = dataHistoryProjectDetail.filter((item: any) => {
          return (
            removeAccents(item?.name?.toLowerCase()).includes(text) ||
            removeAccents(item?.project?.projectCode?.toLowerCase()).includes(text)
          );
        });
        setData(temp);
      } else setData(dataHistoryProjectDetail);
    }
  }, [dataHistoryProjectDetail, text]);

  const COLUMNS = [
    {
      title: t('overtime:project_code'),
      dataIndex: 'projectCode',
      key: 'projectCode',
      width: 100,
      minWidth: 100,
      align: 'left',
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        const obj: any = {
          children: record?.project?.projectCode || '',
          props: { style: { verticalAlign: 'top' } },
        };
        if (
          index >= 1 &&
          data[trueIndex - 1]?.project?.projectCode === record?.project?.projectCode
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== data.length &&
            data[trueIndex + i]?.project?.projectCode === record?.project?.projectCode;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: t('overtime:project_name'),
      dataIndex: 'name',
      key: 'name',
      width: 100,
      minWidth: 100,
      align: 'left',
      render: function (text: any, record: any, index: any) {
        //return record?.project?.projectCode || '';
        const trueIndex = index + sizePage * (currentPage - 1);
        const obj: any = {
          children: text,
          props: { style: { verticalAlign: 'top' } },
        };
        if (
          index >= 1 &&
          data[trueIndex - 1]?.project?.projectCode === record?.project?.projectCode
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== data.length &&
            data[trueIndex + i]?.project?.projectCode === record?.project?.projectCode;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: 'Updated at',
      dataIndex: 'updatedAt',
      align: 'center',
      key: 'updatedAt',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return text ? moment(text).format(formatDatetime) : null;
      },
    },
    {
      title: t('timesheet:start_date'),
      dataIndex: 'startDate',
      key: 'startDate',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return text ? moment(text).format(dateFormat) : null;
      },
    },
    {
      title: t('timesheet:End_date'),
      dataIndex: 'endDate',
      key: 'endDate',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return text ? moment(text).format(dateFormat) : null;
      },
    },
    {
      title: t('overtime:description'),
      dataIndex: 'description',
      key: 'description',
      width: 100,
      minWidth: 100,
      align: 'left',
      render: function (text: any, record: any, index: any) {
        return text || '';
      },
    },
    {
      title: t('timesheet:Status'),
      dataIndex: 'status',
      key: 'status',
      width: 120,
      minWidth: 120,
      align: 'left',
      render: function (text: any, record: any, index: any) {
        return <StatusView text={text} />;
      },
    },
    {
      title: 'Updated by',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      width: 200,
      minWidth: 200,
      render: function (text: any, record: any, index: any) {
        return (
          <EmployeeColumn avatar={text?.fullName} fullName={text?.fullName} email={text?.email} />
        );
      },
    },
  ];

  const onSearchList = (key: string) => {
    const txt = removeAccents(key.toLowerCase());
    if (txt) {
      setText(txt);
    } else {
      setText('');
    }
  };

  const exportToExcel = () => {
    if (!data || data.length === 0) return;

    // Prepare data for export
    const exportData = data.map((item: any) => ({
      'Line Code': item?.project?.projectCode || '',
      Line: item?.name,
      'Updated At': item?.updatedAt ? moment(item?.updatedAt).format(formatDatetime) : '',
      'Start Date': item?.startDate ? moment(item?.startDate).format(dateFormat) : '',
      'End Date': item?.endDate ? moment(item?.endDate).format(dateFormat) : '',
      Description: item?.description || '',
      Status: item?.status || '',
      'Updated By': item?.updatedBy?.fullName || '',
    }));

    // Create worksheet
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Create workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Line History');

    // Generate Excel file
    const fileName = `Line_Report_Project_${moment().format('YYYY-MM-DD_HH-mm')}.xlsx`;
    XLSX.writeFile(workbook, fileName);
  };

  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '100%', display: 'flex' }}>
          <SearchContainer>
            <ITVSearch
              style={{ width: 'inherit' }}
              handleSearch={(value: any) => {
                onSearchList(value);
              }}
            />
          </SearchContainer>
          <div style={{ marginLeft: 10 }}>
            <Select
              showSearch
              style={{ width: 200 }}
              defaultValue={project}
              onChange={(value: any) => setProject(value)}
              filterOption={(input, option: any) =>
                (option!.value as unknown as string).toLowerCase().includes(input.toLowerCase())
              }
            >
              <Option value="">{t('overtime:all_projects')}</Option>
              {listProject?.map((item: any) => {
                return (
                  <Option value={item?.projectCode} title={item?.name}>
                    {item?.projectCode} - {item?.name}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div style={{ marginLeft: 10 }}>
            <Select
              onChange={(value: any) => setProjectStatusState(value)}
              defaultValue=""
              style={{ width: 100 }}
              filterOption={(input, option: any) =>
                (option!.value as unknown as string).toLowerCase().includes(input.toLowerCase())
              }
            >
              {projectStatus?.map((item: any) => (
                <Option value={item?.key}>{item?.value}</Option>
              ))}
            </Select>
          </div>
          <div style={{ marginLeft: 10 }}>
            <Button type="primary" onClick={exportToExcel} disabled={!data || data.length === 0}>
              {t('Export Excel')}
            </Button>
          </div>
        </div>
      </div>
      <ITVTable
        isRowSelect={false}
        loading={loadingDataHistoryProjectDetail}
        columns={COLUMNS}
        isScroll={true}
        width={'max-content'}
        data={data}
        setOffset={setCurrentPage}
        offset={currentPage}
        setLimit={setSizePage}
        limit={sizePage}
      />
      {mode !== '' && (
        <Modal
          visible={mode !== ''}
          style={{ top: 0 }}
          title={'View user detail'}
          okText={t('timesheet:ok')}
          cancelText={t('timesheet:Cancel')}
          width={800}
          onCancel={() => {
            setMode('');
            setCurrentItem(null);
          }}
          maskClosable={false}
          footer={null}
          destroyOnClose
        >
          <ViewProjectDetail record={currentItem} />
        </Modal>
      )}
    </Container>
  );
};

export default ViewProject;

export const SearchContainer = styled.div`
  width: 200px;
  margin-bottom: 20px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background: white;
  width: 100%;
  height: max-content;
`;
