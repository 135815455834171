import React, { useState, useEffect } from 'react';
import { Modal, Button, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ViewAddMember } from '../PayRuns/ViewAddMember';
import payrollApi from 'api/payrollApi';
import { useAppDispatch } from 'app/hooks';
import { setCheckRow } from 'features/employee/employeeSlice';

interface SelectEmployeeModalProps {
  year: string;
  onSuccess: () => void;
}

const SelectEmployeeModal: React.FC<SelectEmployeeModalProps> = ({ year, onSuccess }) => {
  const { t } = useTranslation(['payroll', 'common']);
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const [selectUser, setSelectUser] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dataOrigin, setDataOrigin] = useState<any[]>([]);

  const handleOpenModal = () => {
    setVisible(true);
    setSelectUser([]);
    dispatch(setCheckRow([]));
  };

  const handleCloseModal = () => {
    setVisible(false);
    setSelectUser([]);
    dispatch(setCheckRow([]));
  };

  // Reset selection when component unmounts
  useEffect(() => {
    return () => {
      dispatch(setCheckRow([]));
    };
  }, [dispatch]);

  const handleAddEmployees = async () => {
    if (!selectUser || selectUser.length === 0) {
      message.warning(t('payroll:message.please_select_employee'));
      return;
    }

    try {
      setIsSubmitting(true);
      console.log('Adding employees with IDs:', selectUser);
      console.log('Year:', year);
      console.log('Request payload:', { userIds: selectUser, year });
      const response = await payrollApi.addEmpployeePayroll13th(year, selectUser);
      console.log('API response:', response);

      if (response?.data?.success) {
        message.success(t('payroll:create_13th_month_salary_success'));
        // Đóng modal trước khi gọi onSuccess để tránh gọi API nhiều lần
        handleCloseModal();
        // Gọi hàm onSuccess để cập nhật danh sách sau một khoảng thời gian ngắn
        // để đảm bảo server có đủ thời gian xử lý
        console.log('Calling onSuccess to refresh data after short delay');
        setTimeout(() => {
          onSuccess();
        }, 500);
      }
    } catch (error: any) {
      console.error('Error adding 13th month salary for specific employees:', error);
      message.error(error?.message || t('payroll:message.error'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={handleOpenModal}
        id="add-employees-button"
      >
        {t('payroll:add_employees')}
      </Button>

      <Modal
        visible={visible}
        title={t('payroll:add_employees_to_13th_month')}
        wrapClassName="add-employees-modal"
        data-testid="add-employees-modal"
        okText={t('payroll:button.add')}
        cancelText={t('payroll:button.cancel')}
        width={800}
        maskClosable={false}
        onCancel={handleCloseModal}
        okButtonProps={{
          disabled: !selectUser || selectUser.length === 0,
          loading: isSubmitting,
        }}
        onOk={handleAddEmployees}
        destroyOnClose
      >
        <ViewAddMember dataOrigin={dataOrigin} setSelectUser={setSelectUser} />
      </Modal>
    </>
  );
};

export default SelectEmployeeModal;
