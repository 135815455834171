import { Modal } from 'antd';
import styled from 'styled-components';
import { CloseIcon } from 'assets/icons';
import React from 'react';

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    .ant-modal-header {
      border-bottom: none;
      border-radius: 12px;
      padding: 16px 24px 8px;
    }
    .ant-modal-body {
      padding: 8px 24px 16px;
    }
  }
`;

interface ViewFilePDFModalProps {
  pdfURL: any;
  isOpen: boolean;
  onCancel?: () => void;
}

export function ViewFilePDFModal({ pdfURL, isOpen, onCancel }: ViewFilePDFModalProps) {
  return (
    <React.Fragment>
      <StyledModal
        title="View Document"
        open={isOpen}
        closeIcon={<CloseIcon />}
        width={1500}
        footer={null}
        maskClosable={false}
        onCancel={onCancel}
        style={{ top: '5%' }}
      >
        <div>
          <iframe src={pdfURL} width="100%" height="600px" />
        </div>
      </StyledModal>
    </React.Fragment>
  );
}
