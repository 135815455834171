import React, { Dispatch, SetStateAction, useRef } from 'react';
import { Table, TablePaginationConfig, Button, Tooltip, Modal, Dropdown, Menu, Input } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import moment from 'moment';

import { ThirteenthMonthSalaryData } from 'types';
import { MONTH_NAMES } from 'constants/commons';
import ITVTable, { COLUMNS } from '../../../../../../components/ITVTable';
import EmployeeColumn from 'components/EmployeeColumn';
import { DownTrendIcon, UpTrendIcon } from 'assets/icons';
import MaskedValueRenderer from 'components/MaskedValueRenderer';
import payrollApi from 'api/payrollApi';
import { message } from 'antd';
import { DeleteOutlined, PlusOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface ThirteenthMonthSalaryListProps {
  loading: boolean;
  data: ThirteenthMonthSalaryData[];
  totalResult: number;
  onTableChange: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any>,
  ) => void;
  page: number;
  limit: number;
  setPage: Dispatch<SetStateAction<number>>;
  setLimit: Dispatch<SetStateAction<number>>;
  refetch: () => void;
  activeTab: string;
  year: string;
}

interface StyledPercentageProps {
  showUpTrendIcon?: boolean;
  showDownTrendIcon?: boolean;
}

const StyledPercentage = styled.div<StyledPercentageProps>`
  ${(props) => {
    if (props.showUpTrendIcon) {
      return css`
        color: var(--cl_success500);
      `;
    }
    if (props.showDownTrendIcon) {
      return css`
        color: var(--cl_error500);
      `;
    }
    return css`
      color: var(--cl_neutral900);
    `;
  }}
`;

const StyledWrapperTable = styled.div`
  .ant-table-summary {
    background: #d8fff3 !important;
  }
  .ant-table-summary tr td:first-child,
  .ant-table-summary tr td[data-column-key='personal_income_tax'],
  .ant-table-summary tr td[data-column-key='net_salary'],
  .ant-table-summary tr td:nth-last-child(-n + 2) {
    background: #d8fff3 !important;
  }

  .ant-table-summary tr td {
    font-weight: 700 !important;
  }

  .ant-table {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      transition: none !important;
    }

    .ant-table-cell {
      transition: none !important;
    }

    .ant-table-cell-fix-left {
      transition: none !important;
    }
  }
`;

const StyledTotalText = styled.span`
  font-weight: 800;
  padding-left: 16px;
  text-align: left;
  display: block;
`;

const StyledTotalValue = styled.div`
  color: var(--cl_error500);
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
`;

const StyledSummaryCell = styled(Table.Summary.Cell)`
  width: 150px !important;
  min-width: 150px !important;
  position: sticky !important;
  right: 0 !important;
  background: #d8fff3 !important;
  padding: 8px 16px !important;
  z-index: 999 !important;
  box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15) !important;
`;

const StyledActionButton = styled(Button)`
  padding: 4px !important;
  height: auto !important;
  font-size: 18px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  &:hover {
    background: transparent !important;
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  display: flex;
  align-items: center;
  padding: 10px 16px;
  transition: all 0.3s ease;
  border-radius: 4px;
  margin: 4px;

  &:hover {
    background-color: #fff1f0;
  }

  .anticon {
    font-size: 14px;
    margin-right: 8px;
    color: #ff4d4f;
  }

  &.ant-dropdown-menu-item-danger {
    color: #ff4d4f;
  }
`;

const StyledMenu = styled(Menu)`
  padding: 4px;
  min-width: 120px;
  border-radius: 6px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
`;

const ThirteenthMonthSalaryList: React.FC<ThirteenthMonthSalaryListProps> = ({
  loading,
  data,
  totalResult,
  onTableChange,
  page,
  limit,
  setPage,
  setLimit,
  refetch,
  activeTab,
  year,
}) => {
  const { t } = useTranslation(['employee', 'payroll', 'common']);

  const handleAdd = async (userId: string) => {
    try {
      await payrollApi.createPayroll13th(year);
      refetch();
    } catch (error: any) {
      message.error(t('common:message.error'));
    }
  };

  const handleDelete = (userId: string) => {
    Modal.confirm({
      title: t('payroll:confirm_delete_13th_month'),
      icon: <ExclamationCircleOutlined />,
      content: t('payroll:confirm_delete_13th_month_content'),
      okText: t('payroll:button.ok'),
      cancelText: t('payroll:button.cancel'),
      onOk: async () => {
        try {
          console.log('Deleting payroll 13th:', { userId, year });
          console.log(
            'API endpoint being called:',
            `/payroll/delete-payroll-13th/${userId}/${year}`,
          );
          const response = await payrollApi.deletePayroll13th(userId, year);
          console.log('Delete payroll 13th success, response:', response);
          message.success(t('payroll:delete_13th_month_success'));

          // Kiểm tra số lượng dữ liệu hiện tại trên trang
          // Nếu chỉ còn 1 item và đang không ở trang 1, cần quay về trang trước đó
          if (data.length === 1 && page > 1) {
            console.log('Last item on page deleted, moving to previous page');
            setPage(page - 1);
          } else {
            // Đợi một chút trước khi refetch để đảm bảo server đã xử lý xong
            setTimeout(() => {
              console.log('Refetching data after delete');
              refetch();
            }, 500);
          }
        } catch (error: any) {
          console.error('Delete payroll 13th error:', error);
          message.error(error?.message || t('common:message.error'));
        }
      },
    });
  };

  const monthColumnList: COLUMNS[] = MONTH_NAMES.map((_, index) => ({
    title: t(`common:month.${index}.name`),
    width: 300,
    minWidth: 300,
    children: [
      {
        title: t('payroll:actual_hours'),
        dataIndex: 'array',
        key: 'array',
        align: 'center',
        width: 120,
        minWidth: 120,
        render: (data: any) => {
          const actualHours =
            data?.[index]?.dataPayroll?.timeSheet?.actualHours ||
            data?.[index]?.dataPayroll?.dataPayroll?.timeSheet?.actualHours ||
            0;
          return <div>{actualHours}</div>;
        },
      },
      {
        title: t('payroll:gross_salary'),
        dataIndex: 'array',
        key: 'array',
        align: 'center',
        width: 150,
        minWidth: 150,
        render: (data: any) => {
          const grossSalary =
            data?.[index]?.dataPayroll?.totalGrossSalary ||
            data?.[index]?.dataPayroll?.dataPayroll?.totalGrossSalary ||
            0;
          return <MaskedValueRenderer value={grossSalary} />;
        },
      },
    ],
  }));

  const thirteenthMonthColumn: COLUMNS = {
    title: t('payroll:13th_month_gross'),
    width: 180,
    minWidth: 180,
    dataIndex: 'array',
    key: 'array',
    align: 'center',
    fixed: 'right',
    render: (data: any) => {
      const payroll13th = data?.[12]?.dataPayroll?.payroll13th || 0;
      return <MaskedValueRenderer value={payroll13th} />;
    },
  };

  const netSalaryColumn: COLUMNS = {
    title: t('payroll:net_salary_13th'),
    width: 180,
    minWidth: 180,
    dataIndex: 'array',
    key: 'net_salary',
    align: 'center',
    fixed: 'right',
    render: (data: any) => {
      const net13thSalary = data?.[12]?.dataPayroll?.netIncome || 0;
      return <MaskedValueRenderer value={net13thSalary} />;
    },
  };

  const personalIncomeTaxColumn: COLUMNS = {
    title: t('payroll:personal_income_tax'),
    width: 180,
    minWidth: 180,
    dataIndex: 'array',
    key: 'personal_income_tax',
    align: 'center',
    render: (data: any) => {
      const personalIncomeTax = data?.[12]?.dataPayroll?.personalIncomeTax || 0;
      return <MaskedValueRenderer value={personalIncomeTax} />;
    },
  };

  const actionColumn: COLUMNS = {
    title: t('payroll:action'),
    width: 80,
    minWidth: 80,
    fixed: 'right',
    align: 'center',
    className: 'action-column',
    render: (data: any) => {
      console.log('Action data:', data);
      const userId = data?.array?.find((item: any) => item?.userId)?.userId;
      console.log('userId:', userId);

      const menu = (
        <StyledMenu>
          <StyledMenuItem
            key="delete"
            danger
            onClick={() => handleDelete(userId)}
            icon={<DeleteOutlined />}
          >
            {t('payroll:button.delete')}
          </StyledMenuItem>
        </StyledMenu>
      );

      return (
        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
          <Button type="text" icon={<MoreOutlined style={{ fontSize: '16px' }} />} />
        </Dropdown>
      );
    },
  };

  const employeeColumn: COLUMNS = {
    title: t('payroll:employee'),
    dataIndex: 'full_name',
    key: 'full_name',
    width: 220,
    minWidth: 220,
    ellipsis: true,
    sorter: (a: any, b: any) => {
      if (!a.full_name && !b.full_name) return 0;
      if (!a.full_name) return 1;
      if (!b.full_name) return -1;
      return a.full_name.localeCompare(b.full_name);
    },
    fixed: 'left',
    align: 'left',
    className: 'employee-column',
    render: function (text: any, record: any) {
      return (
        <EmployeeColumn
          avatar={record?.full_name}
          fullName={record?.full_name}
          email={record?.email}
        />
      );
    },
  };

  const employeeColumnGross: COLUMNS = {
    title: t('payroll:employee'),
    dataIndex: 'full_name',
    key: 'full_name',
    width: 220,
    minWidth: 220,
    ellipsis: true,
    sorter: (a: any, b: any) => {
      if (!a.full_name && !b.full_name) return 0;
      if (!a.full_name) return 1;
      if (!b.full_name) return -1;
      return a.full_name.localeCompare(b.full_name);
    },
    fixed: 'left',
    align: 'left',
    className: 'employee-column',
    render: function (text: any, record: any) {
      return (
        <EmployeeColumn
          avatar={record?.full_name}
          fullName={record?.full_name}
          email={record?.email}
        />
      );
    },
  };

  const getColumns = () => {
    const baseColumns = [
      {
        title: 'ID',
        dataIndex: 'employee_id',
        key: 'employee_id',
        align: 'center',
        width: 70,
        minWidth: 70,
        ellipsis: true,
        sorter: (a: any, b: any) => {
          const idA = a.employee_id || '';
          const idB = b.employee_id || '';
          return idA.localeCompare(idB);
        },
        fixed: 'left',
        render: (text: any) => <div>{text}</div>,
      },
      employeeColumn,
    ];

    const baseColumnsGross = [
      {
        title: 'ID',
        dataIndex: 'employee_id',
        key: 'employee_id',
        align: 'center',
        width: 70,
        minWidth: 70,
        ellipsis: true,
        sorter: (a: any, b: any) => {
          const idA = a.employee_id || '';
          const idB = b.employee_id || '';
          return idA.localeCompare(idB);
        },
        fixed: 'left',
        render: (text: any) => <div>{text}</div>,
      },
      employeeColumnGross,
    ];

    if (activeTab === 'gross') {
      return [...baseColumnsGross, ...monthColumnList, thirteenthMonthColumn, actionColumn];
    }

    return [...baseColumns, personalIncomeTaxColumn, netSalaryColumn, actionColumn];
  };

  return (
    <StyledWrapperTable>
      <ITVTable
        scroll={{ x: 'max-content' }}
        columns={getColumns()}
        data={data}
        totalResult={totalResult}
        loading={loading}
        rowKey={['id']}
        setOffset={setPage}
        offset={page}
        setLimit={setLimit}
        limit={limit}
        onChange={onTableChange}
        isRowSelect={false}
        summary={() => (
          <Table.Summary fixed="top">
            <Table.Summary.Row>
              {activeTab === 'gross' ? (
                <Table.Summary.Cell index={0} colSpan={2} align="left">
                  <StyledTotalText>{t('payroll:total')}</StyledTotalText>
                </Table.Summary.Cell>
              ) : (
                <Table.Summary.Cell index={0} colSpan={2} align="left">
                  <StyledTotalText>Total</StyledTotalText>
                </Table.Summary.Cell>
              )}
              {activeTab === 'gross' ? (
                <>
                  {MONTH_NAMES.map((_, index) => (
                    <React.Fragment key={index}>
                      <Table.Summary.Cell index={index * 2 + 2} colSpan={2} align="center">
                        <MaskedValueRenderer
                          value={data.reduce((acc, item) => {
                            return acc + (item.array?.[index]?.dataPayroll?.totalGrossSalary || 0);
                          }, 0)}
                        />
                      </Table.Summary.Cell>
                    </React.Fragment>
                  ))}
                  <Table.Summary.Cell index={26} align="center">
                    <MaskedValueRenderer
                      value={data.reduce((acc, item) => {
                        return acc + (item.array?.[12]?.dataPayroll?.payroll13th || 0);
                      }, 0)}
                    />
                  </Table.Summary.Cell>
                </>
              ) : (
                <>
                  <Table.Summary.Cell index={2} align="center">
                    <MaskedValueRenderer
                      value={data.reduce((acc, item) => {
                        return acc + (item.array?.[12]?.dataPayroll?.personalIncomeTax || 0);
                      }, 0)}
                    />
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="center">
                    <MaskedValueRenderer
                      value={data.reduce((acc, item) => {
                        return acc + (item.array?.[12]?.dataPayroll?.netIncome || 0);
                      }, 0)}
                    />
                  </Table.Summary.Cell>
                </>
              )}
              <Table.Summary.Cell index={activeTab === 'gross' ? 27 : 4} align="center" />
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </StyledWrapperTable>
  );
};

export default ThirteenthMonthSalaryList;
